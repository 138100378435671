import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { token } from '../../queries/token';
import { getCategories } from '../../queries/categories/getCategories';
import { Category } from '../../../interfaces/Product';

export function useCategories({
  options
}: {
  options?: UseQueryOptions<Category[], unknown, Category[], string[]>;
}): UseQueryResult<Category[]> {
  return useQuery(
    ['categories'],
    async () => getCategories({ token: token() }),
    options
  );
}
