import { useCallback, useState } from 'react';
interface Props {
  modalId: string;
}
export const useModal = ({ modalId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsOpen(true);
    if (window[modalId] && window[modalId].showModal) {
      window[modalId].showModal();
    }
  }, [modalId]);

  const closeModal = useCallback(() => {
    if (window[modalId] && window[modalId].close) {
      window[modalId].close();
    }
  }, [modalId]);

  return {
    openModal,
    closeModal,
    isOpen
  };
};
