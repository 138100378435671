import React, { useMemo } from 'react';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Offer } from '../../interfaces/Offer';
import ProductAmountInput from '../Cart/CartList/ProductAmountInput';
import { useCart } from '../../provider/Cart/CartProvider';
import { useMultiBaskets } from '../../provider/Basket/MultiBasketsProvider';
import { useDeviceType } from '../../utils/hooks/useDeviceType';

interface Props {
  offer: Offer;
  isSearch?: boolean;
  handleAdd?: (offer: Offer, value: number | undefined) => void;
}

export const OfferButtons = ({
  handleAdd,
  offer,
  isSearch = false
}: Props): JSX.Element => {
  const { state } = useCart();
  const {
    state: { baskets }
  } = useMultiBaskets();

  const { isCellphone } = useDeviceType();

  const quantity = useMemo(() => {
    return state.items.find((item) => item.id === offer.id)?.quantity;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baskets, state, offer]);

  return (
    <Flex
      direction="col"
      className="gap-4"
      onClick={(e) => {
        if (isSearch) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      {quantity && Number(quantity) > 0 ? (
        <ProductAmountInput
          offer={offer}
          value={Number(quantity)}
          callbackUpdateAmount={(offerCallback, value) =>
            handleAdd?.(offerCallback as Offer, value)
          }
        />
      ) : (
        <Button
          className="w-full bg-primary-pure rounded-md font-[600]  text-[13px] sm:text-[16px] flex items-center justify-center"
          size={isCellphone ? 'sm' : 'md'}
          onClick={() => handleAdd?.(offer, 1)}
          label="Adicionar ao carrinho"
        />
      )}
    </Flex>
  );
};
