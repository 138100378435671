import { debounce } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Flex } from '../../../components/Flex';
import { Offer } from '../../../interfaces/Offer';
import Icons from '../../../../public/assets/images/icons';
import { PurchaseOfferItem } from '../../../interfaces/Order';

const debouncedUpdateAmount = debounce((callbackUpdateAmount: () => void) => {
  callbackUpdateAmount();
}, 300);

const ProductAmountInput: FC<{
  value: number;
  callbackUpdateAmount: (
    offer: Offer | PurchaseOfferItem,
    amount: number
  ) => void;
  offer: Offer | PurchaseOfferItem;
}> = ({ value, callbackUpdateAmount, offer }) => {
  const [debounced, setDebounced] = useState<number>(value);

  const handleAmountUpdate = (newValue: number) => {
    setDebounced(newValue);
    debouncedUpdateAmount(() => {
      callbackUpdateAmount(offer, newValue);
    });
  };

  useEffect(() => {
    setDebounced(value);
  }, [value]);

  return (
    <Flex
      align="center"
      className="h-12 flex items-center w-full border-[1px] border-neutral-pureOp10 rounded-md p-1"
    >
      <button
        onClick={() =>
          handleAmountUpdate(debounced - 1 >= 0 ? debounced - 1 : 0)
        }
        className="p-[6px] flex items-center justify-center aspect-square bg-neutral-pure200 border-primary-pure rounded-md disabled:bg-neutral-100"
        disabled={value === 0}
      >
        <Icons.Minus className="w-[20px] h-[20px]" />
      </button>

      <input
        className="text-[14px] font-[600] py-2 px-2 w-full text-center rounded-lg "
        type="number"
        value={debounced}
        min={0}
        onChange={(e) => handleAmountUpdate(parseInt(e.target.value, 10) || 0)}
      />
      <button
        onClick={() => handleAmountUpdate(debounced + 1)}
        className="p-[6px] flex items-center justify-center aspect-square bg-neutral-pure200 border-primary-pure rounded-md disabled:bg-neutral-100"
      >
        <Icons.Plus className="w-[20px] h-[20px]" />
      </button>
    </Flex>
  );
};
export default ProductAmountInput;
