import React, { useEffect, useReducer, ReactNode } from 'react';
import { Company, User } from '../../interfaces/User';
import { AuthContext } from './AuthContext';
import { deleteCookie, setCookie } from 'cookies-next';
import { decodeToken } from '../../utils/auth';
import { useCategories } from '../../http/hooks/categories/getCategories';
import { Category } from '../../interfaces/Product';
import { token } from '../../http/queries/token';
import { useRouter } from 'next/router';

interface AuthProviderProps {
  children: ReactNode;
}

interface State {
  user?: User | null;
  selectedCompany: Company | null;
}

type Action =
  | { type: 'SET_USER'; payload?: User | null }
  | { type: 'SET_COMPANY'; payload: Company | null };

const initialState = {
  user: undefined as User | undefined,
  selectedCompany: null as Company | null
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_COMPANY':
      return { ...state, selectedCompany: action.payload };
    default:
      return state;
  }
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const router = useRouter();

  const { data: categories, refetch } = useCategories({
    options: {
      enabled: !!token(),
      refetchOnWindowFocus: false
    }
  });

  const handleLogin = async ({
    token,
    clients
  }: {
    token: string;
    clients: any[];
  }) => {
    const decoded = decodeToken(token);
    if (!decoded) return;
    setCookie('@bakerytech/authToken', token);
    dispatch({
      type: 'SET_USER',
      payload: { ...decoded.user, companies: clients as Company[] }
    });
    await refetch();
  };

  const handleSetSelectedCompany = (company: Company | null) => {
    dispatch({ type: 'SET_COMPANY', payload: company });
    setCookie('@bakerytech/company', company);
  };

  const logout = async () => {
    dispatch({ type: 'SET_USER', payload: undefined });
    dispatch({ type: 'SET_COMPANY', payload: null });

    try {
      await router.push('/login').then(() => {
        deleteCookie('@bakerytech/authToken');
        deleteCookie('@bakerytech/company');
      });
    } catch (e) {
      console.error('Navigation failed', e);
    }
  };

  const logoutWrapper = () => {
    logout().catch((err) => console.error('Logout failed:', err));
  };

  const handleSetUser = (user: User | null) => {
    dispatch({ type: 'SET_USER', payload: user });
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        logout: logoutWrapper,
        handleSetUser,
        handleLogin,
        handleSetSelectedCompany,
        selectedCompany: state.selectedCompany,
        categories: categories || ([] as Category[])
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
