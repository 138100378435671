import React from 'react';
import { SideModal } from '../../../components/Modal/SideModal';
import { Flex } from '../../../components/Flex';
import { useCart } from '../../../provider/Cart/CartProvider';
import Icons from '../../../../public/assets/images/icons';
import { Button } from '../../../components/Button';
import { useRouter } from 'next/router';
import { HorizontalOfferItem } from '../../Offer/HorizontalOfferItem';
import { useHandleCart } from '../../../utils/hooks/useHandleCart';
import { formatCurrency } from '../../../utils/helpers/formatters';
import { Offer } from '../../../interfaces/Offer';
import { useDeleteCartItems } from '../../../http/mutations/Cart/useDeleteCartItem';
import { emptyCart } from '../../../provider/Cart/actions';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
}

export const CartModal = ({ isOpen }: Props): JSX.Element => {
  const router = useRouter();

  const { handleAddProductToCart, handleDeleteProduct } = useHandleCart(false);

  const { state, dispatch, setIsOpen } = useCart();

  const { mutateAsync: deleteAsync } = useDeleteCartItems({});

  const handleAdd = (offer: Offer, value?: number) => {
    if (value === 0) handleDeleteProduct(offer);
    handleAddProductToCart(offer, value);
  };

  const handleEmptyCart = () => {
    deleteAsync({
      offers: state.items.map((item) => item.id)
    })
      .then(() => {
        dispatch(emptyCart());
        toast.success('Carrinho esvaziado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao esvaziar carrinho');
      });
  };

  return (
    <SideModal isOpen={isOpen} handleClose={setIsOpen}>
      <Flex
        className="w-full h-[100vh] bg-white p-4"
        justify="between"
        direction="col"
      >
        <Flex direction="col" className="gap-2 max-h-[75dvh] lg:max-h-[80vh]">
          <Flex direction="col" className="gap-2 relative">
            <Flex className="gap-1">
              <p className="text-[16px] font-[600] text-neutral-pure1000 inline">
                Carrinho de compras (
              </p>
              <p className="text-[16px] font-[600] text-primary-pure inline">
                {state.items.length}
              </p>
              <p className="text-[16px] font-[600] text-neutral-pure1000 inline">
                itens)
              </p>
            </Flex>
            <Button
              className="  top-[30px] w-fit normal-case bg-neutral-pure100 text-primary-pure border-none hover:bg-primary-pure10"
              onClick={handleEmptyCart}
              size="sm"
              disabled={state.items.length === 0}
            >
              <Icons.Trash className="text-error-pure mt-[-4px]" />
              Esvaziar carrinho
            </Button>
          </Flex>
          <Flex className="w-full flex-col mt-[-20px]">
            <div className="divider" />
          </Flex>
          <Flex
            className="w-full flex-col overflow-y-auto overflow-x-hidden h-full"
            align="center"
            justify="center"
          >
            {state.total === 0 ? (
              <Flex direction="col" align="center" className="gap-10">
                <Icons.EmptyCart className="w-[200px] h-[200px]" />
                <Flex direction="col" align="center" className="gap-2">
                  <p className="font-[600] text-[20px] text-neutral-pure1000">
                    Está na hora de fazer suas compras
                  </p>
                  <p className="font-[600] text-[16px] text-neutral-pure700">
                    Seu carrinho está vazio
                  </p>
                </Flex>
                <Flex direction="col" align="center" className="gap-2">
                  <p className="font-[600] text-[16px] text-neutral-pure700">
                    Veja nossas recomendações de cesta
                  </p>
                  <Button
                    onClick={() => {
                      if (router.route === '/') return;
                      router.push('/');
                    }}
                    className="px-2 border-none bg-primary-pureOp10 normal-case text-primary-pure w-fit hover:bg-primary-pure10"
                  >
                    Ver recomendações
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Flex direction="col" className="gap-4 h-full" justify="between">
                <Flex direction="col" className="h-full pb-11 lg:pb-0  gap-1">
                  {state.items.map((item) => (
                    <HorizontalOfferItem
                      handleDeleteProduct={handleDeleteProduct}
                      handleAdd={(offerToAdd, value) =>
                        handleAdd(offerToAdd as Offer, value)
                      }
                      offer={item}
                      key={item.id}
                    />
                  ))}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        {state.items.length > 0 && (
          <Flex
            direction="col"
            align="end"
            className="fixed  md:relative bottom-0 pb-4 md:bottom-0 left-0 w-full bg-white h-fit gap-1"
          >
            <Flex className="hidden md:flex w-full gap-4" direction="col">
              <Flex justify="between" align="center">
                <p className="font-[600] text-[14px]">Subtotal</p>
                <p className="font-[600] text-[18px] text-primary-pure">
                  {formatCurrency(state.total)}
                </p>
              </Flex>
              <Button
                onClick={() => {
                  setIsOpen();
                  router.push('/carrinho');
                }}
                variant="success"
                className="normal-case text-white"
              >
                Fechar pedido
              </Button>
            </Flex>
            <Flex className="px-6 w-full" direction="col">
              <Flex className="md:hidden" justify="between" align="center">
                <p className="font-[600] text-[14px]">Subtotal</p>
                <p className="font-[600] text-[18px] text-primary-pure">
                  {formatCurrency(state.total)}
                </p>
              </Flex>
              <Button
                onClick={() => {
                  setIsOpen();
                  router.push('/carrinho');
                }}
                variant="success"
                className="normal-case text-white lg:hidden"
              >
                Fechar pedido
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </SideModal>
  );
};
