/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { token } from '../../queries/token';
import { getSellers } from '../../queries/seller/getSellers';
import { Seller } from '../../../interfaces/Seller';

export function useSellers({
  options
}: {
  options?: UseQueryOptions<Seller[], unknown, Seller[], string[]>;
}): UseQueryResult<Seller[]> {
  return useQuery(
    ['sellers'],
    async () => getSellers({ token: token() }),
    options
  );
}
