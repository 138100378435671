import { QueryClient, QueryClientProvider } from 'react-query';
import AuthProvider from '../provider/Auth/AuthProvider';
import 'tailwindcss/tailwind.css';
import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import type { AppProps } from 'next/app';
import { CookieAuthenticator } from '../features/CookieAuthenticator';
import { ToastContainer } from 'react-toastify';
import { CartProvider } from '../provider/Cart/CartProvider';
import { MultiBasketsProvider } from '../provider/Basket/MultiBasketsProvider';
import { useEffect, useState } from 'react';
import { Router } from 'next/router';
import PageLoading from '../components/PageLoading';
const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);
    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);
  return (
    <>
      <ToastContainer
        theme="dark"
        limit={5}
        autoClose={10000}
        className="z-[999999]"
        style={{ zIndex: 999999 }}
      />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <CookieAuthenticator>
            <MultiBasketsProvider>
              <CartProvider>
                <PageLoading isLoading={loading}>
                  <Component {...pageProps} />
                </PageLoading>
              </CartProvider>
            </MultiBasketsProvider>
          </CookieAuthenticator>
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
