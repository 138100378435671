import { Cart } from '../../../interfaces/Cart';
import { api } from '../../axios/auth';

export const getCartItems = async ({
  token,
  companyId
}: {
  companyId: number;
  token?: string;
}): Promise<Cart[]> => {
  const response = await api.get<Cart[]>(`/cart/?client=${companyId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
};
