import { useCart } from '../../provider/Cart/CartProvider';
import { addItem, removeCartItem } from '../../provider/Cart/actions';
import { Offer } from '../../interfaces/Offer';
import { useModal } from './useModal';
import { toast } from 'react-toastify';
import { CartItem } from '../../interfaces/Cart';
import { useMutateCart } from '../../http/mutations/Cart/useMutateCart';
import { useDeleteCartItems } from '../../http/mutations/Cart/useDeleteCartItem';

export const useHandleCart = (isNotCart = true) => {
  const { dispatch } = useCart();

  const { openModal } = useModal({ modalId: 'deleteProduct' });

  const { mutateAsync: deleteAsync } = useDeleteCartItems({});

  const handleDeleteProduct = (cartItem: CartItem) => {
    deleteAsync({
      offers: [cartItem.id]
    }).then(() => {
      dispatch(removeCartItem(cartItem));
      toast.success('Produto removido do carrinho com sucesso');
    });
  };

  const { mutateAsync } = useMutateCart({});

  const handleAddProductToCart = (offer: Offer, value?: number) => {
    if (value === 0) {
      isNotCart ? openModal() : handleDeleteProduct(offer);
      return;
    }

    mutateAsync({
      offers: [
        {
          id: offer.id,
          quantity: value || 1
        }
      ]
    }).then(() => {
      dispatch(
        addItem({
          ...offer,
          quantity: value || 1
        })
      );
      if (isNotCart) toast.success('Produto adicionado ao carrinho');
    });
  };
  return {
    handleAddProductToCart,
    handleDeleteProduct
  };
};
