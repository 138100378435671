import { CartItem } from '../../interfaces/Cart';
import { Seller } from '../../interfaces/Seller';
import { CartActions, CartTypes } from './reducer';

export const addItem = (payload: CartItem): CartActions => ({
  type: CartTypes.ADD_ITEM,
  payload
});

export const addMultipleItems = (payload: CartItem[]): CartActions => ({
  type: CartTypes.ADD_MULTIPLE_ITEMS,
  payload
});

export const getCartItems = (payload: CartItem[]): CartActions => ({
  type: CartTypes.GET_ITEMS,
  payload
});

export const updateCartItems = (payload: CartItem[] | []): CartActions => ({
  type: CartTypes.UPDATE_CART,
  payload
});

export const removeCartItem = (payload: CartItem): CartActions => ({
  type: CartTypes.REMOVE_ITEM,
  payload
});

export const emptyCart = (): CartActions => ({
  type: CartTypes.EMPTY_CART
});

export const getSellersMinimum = (payload: Seller[]): CartActions => ({
  type: CartTypes.GET_SELLERS_MINIMUM,
  payload
});

export const updateCartItemsWithMinimumCheck = (
  payload: CartItem[]
): CartActions => {
  return {
    type: CartTypes.UPDATE_ITEMS_MINIMUM_CHECK,
    payload
  };
};
