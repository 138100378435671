import { createContext } from 'react';
import { Company, User } from '../../interfaces/User';
import { Category } from '../../interfaces/Product';
interface AuthContextType {
  user?: User | null;
  handleSetUser: (user: User) => void;
  selectedCompany: Company | null;
  handleSetSelectedCompany: (company: Company | null) => void;
  handleLogin: ({
    token,
    clients
  }: {
    token: string;
    clients: any[];
  }) => Promise<void>;
  logout: () => void;
  categories: Category[];
}

export const AuthContext = createContext<AuthContextType>({
  categories: [],
  user: null,
  handleLogin: () => new Promise(() => {}),
  handleSetUser: () => {},
  logout: () => {},
  selectedCompany: {} as Company,
  handleSetSelectedCompany: () => {}
});
