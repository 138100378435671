import { Address } from '../BankData';
import { Offer } from '../Offer';
import { Seller } from '../Seller';
import { PurchaseHistory } from '../User';
import { Payment } from './Checkout';

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type ProductStatus =
  | 'AGUARDANDO PAGAMENTO'
  | 'PAGO'
  | 'CANCELADO'
  | 'RUPTURA'
  | 'ENTREGUE'
  | 'ENVIADO'
  | 'ENTREGUE PARCIAL'
  | 'EXPIRADO';

export const ProductStatusArray = [
  'AGUARDANDO PAGAMENTO',
  'PAGO',
  'CANCELADO',
  'ENVIADO',
  'ENTREGUE',
  'RUPTURA',
  'ENTREGUE PARCIAL'
];

export const productStatusMap: {
  [key in ProductStatus]: string;
} = {
  'AGUARDANDO PAGAMENTO': 'Aguardando pagamento',
  PAGO: 'Pago',
  CANCELADO: 'Cancelado',
  RUPTURA: 'Ruptura',
  ENTREGUE: 'Entregue',
  ENVIADO: 'Enviado',
  'ENTREGUE PARCIAL': 'Entregue parcial',
  EXPIRADO: 'Expirado'
};

export const productStatusColorMap: { [key in ProductStatus]: string } = {
  'AGUARDANDO PAGAMENTO': '#E7E7EA',
  PAGO: '#AFCCE3',
  ENVIADO: '#F2C068',
  ENTREGUE: '#afd49e',
  CANCELADO: '#F8BCAE',
  RUPTURA: '#F8BCAE',
  'ENTREGUE PARCIAL': '#F2C068',
  EXPIRADO: '#F8BCAE'
};

export const productStatusColorTabMap: { [key in ProductStatus]: string } = {
  'AGUARDANDO PAGAMENTO': 'primary-pure',
  PAGO: 'accent-pure',
  CANCELADO: 'error-pure',
  RUPTURA: 'error-pure',
  ENTREGUE: 'success-pure',
  ENVIADO: 'secondary-pure',
  'ENTREGUE PARCIAL': 'warning-pure',
  EXPIRADO: 'error-pure'
};

export const productStatusValues: { [key in ProductStatus]: number } = {
  EXPIRADO: 0,
  'AGUARDANDO PAGAMENTO': 1,
  PAGO: 2,
  CANCELADO: 3,
  ENVIADO: 4,
  ENTREGUE: 5,
  RUPTURA: 6,
  'ENTREGUE PARCIAL': 7
};

export interface StatusOffer {
  id: number;
  status: ProductStatus;
}

export function isOffer(offer?: Offer | PurchaseOfferItem): offer is Offer {
  return (offer as Offer)?.commercial_product !== undefined;
}

export interface PurchaseOfferItem {
  id: number;
  image?: string;
  identifier_code: string;
  description: string;
  custom_price_value: number;
  discount_percentage: number;
  quantity: number;
}

export interface PurchaseOffer {
  created_at: string;
  id: number;
  offer: PurchaseOfferItem | Offer;
  quantity: number;
  quantity_rupture?: number;
  value_rupture?: number;
  updated_at: string;
  status_offer?: StatusOffer;
  unit_original_price_paid?: number;
  unit_price_paid?: number;
}

export interface SpecificOrder {
  order: Order;
  purchase_history: PurchaseHistory;
  seller: Seller & {
    purchase_offers: PurchaseOffer[];
  };
}

export type Order = {
  id: number;
  created_at: string;
  observation?: string;
  updated_at: string;
  delivery_date: string;
  invoice_id: string;
  delivery: Address | number;
  payment?: Payment;
  purchase_offers?: PurchaseOffer[];
  purchase_historys?: PurchaseHistory[];
  invoice: {
    pix?: {
      qrcode: string;
      qrcode_text: string;
      status: string;
    };
    bank_slip?: {
      barcode: string;
      barcode_data: string;
      digitable_line: string;
      bank_slip_status: string;
    };
  };
};
export interface Invoice {
  id: string;
  status: string;
  pix?: {
    qrcode: string;
    qrcode_text: string;
    status: string;
  };
  bank_slip?: {
    barcode: string;
    barcode_data: string;
    digitable_line: string;
    bank_slip_status: string;
  };
}

export interface GenericOrderData {
  supplier?: Seller;
  quantity?: number;
  orderId?: number;
  purchaseOffer?: PurchaseOffer;
}
