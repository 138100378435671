import { Action, ActionMap } from '../../interfaces/Action';
import { CartItem } from '../../interfaces/Cart';
import { Seller } from '../../interfaces/Seller';
import { CartState } from './CartProvider';

export enum CartTypes {
  UPDATE_CART = 'UPDATE_CART',
  ADD_ITEM = 'ADD_ITEM',
  ADD_MULTIPLE_ITEMS = 'ADD_MULTIPLE_ITEMS',
  GET_ITEMS = 'GET_ITEMS',
  REMOVE_ITEM = 'REMOVE_ITEM',
  EMPTY_CART = 'EMPTY_CART',
  GET_SELLERS_MINIMUM = 'GET_SELLERS_MINIMUM',
  UPDATE_ITEMS_MINIMUM_CHECK = 'UPDATE_ITEMS_MINIMUM_CHECK'
}

type CartPayload = {
  [CartTypes.UPDATE_CART]: CartItem[];
  [CartTypes.GET_ITEMS]: CartItem[];
  [CartTypes.REMOVE_ITEM]: CartItem;
  [CartTypes.ADD_ITEM]: CartItem;
  [CartTypes.ADD_MULTIPLE_ITEMS]: CartItem[];
  [CartTypes.EMPTY_CART]: undefined;
  [CartTypes.GET_SELLERS_MINIMUM]: Seller[];
  [CartTypes.UPDATE_ITEMS_MINIMUM_CHECK]: CartItem[];
};

export type CartActions = ActionMap<CartPayload>[keyof ActionMap<CartPayload>];

const updateItemsWithMinimumCheck = (
  items: CartItem[],
  sellersMinimumOrder: { [key: number]: number }
) => {
  const totalValuePerSeller = items.reduce(
    (acc: { [key: number]: number }, item) => {
      const sellerId = item.commercial_product.seller?.id || 0;
      const itemValue =
        (item.quantity || 0) * Number(item.custom_price_value || 0);
      acc[sellerId] = (acc[sellerId] || 0) + itemValue;
      return acc;
    },
    {}
  );

  return items.map((item) => {
    const sellerId = item.commercial_product.seller?.id || 0;
    const hasNotMetMinimum =
      totalValuePerSeller[sellerId] < (sellersMinimumOrder[sellerId] || 0);
    return { ...item, hasNotMetMinimum };
  });
};

export const cartReducer = (state: CartState, action: Action): CartState => {
  switch (action.type) {
    case CartTypes.UPDATE_ITEMS_MINIMUM_CHECK:
      return {
        ...state,
        items: action.payload
      };
    case CartTypes.GET_SELLERS_MINIMUM:
      const sellersMinimumOrder = action.payload.reduce(
        (acc: { [key: number]: number }, seller) => {
          acc[seller.id] = Number(seller.minimum_order || 0);
          return acc;
        },
        {}
      );
      // Update the state with this new data
      // You might need to dispatch an action or directly set the state
      return {
        ...state,
        sellersMinimumOrder
      };
    case CartTypes.ADD_ITEM:
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemIndex !== -1) {
        state.items[itemIndex].quantity = action.payload.quantity;
      } else {
        state.items.push(action.payload);
      }

      const updatedItems = updateItemsWithMinimumCheck(
        state.items,
        state.sellersMinimumOrder
      );

      return {
        ...state,
        items: updatedItems,
        total: updatedItems
          .filter((item) => !item.hasNotMetMinimum)
          .map(
            (item) =>
              Number(item.custom_price_value || 0) * (item.quantity || 1)
          )
          .reduce((a, b) => a + b, 0)
      };
    case CartTypes.ADD_MULTIPLE_ITEMS: {
      const updItems = action.payload.reduce(
        (acc, newItem) => {
          const existingItem = acc.find((item) => item.id === newItem.id);

          if (existingItem) {
            return acc.map((item) =>
              item.id === newItem.id
                ? {
                    ...item,
                    quantity: (item.quantity || 0) + (newItem.quantity || 1)
                  }
                : item
            );
          } else {
            return [...acc, newItem];
          }
        },
        [...state.items]
      );

      const newUpdatedItems = updateItemsWithMinimumCheck(
        updItems,
        state.sellersMinimumOrder
      );

      return {
        ...state,
        items: newUpdatedItems,
        total: newUpdatedItems
          .filter((item) => !item.hasNotMetMinimum)
          .map(
            (item) =>
              Number(item.custom_price_value || 0) * (item.quantity || 1)
          )
          .reduce((a, b) => a + b, 0)
      };
    }

    case CartTypes.UPDATE_CART:
      const newUpdatedItems = updateItemsWithMinimumCheck(
        action.payload,
        state.sellersMinimumOrder
      );
      return {
        ...state,
        items: newUpdatedItems,
        total: newUpdatedItems
          .filter((item) => !item.hasNotMetMinimum)
          .map(
            (item) =>
              Number(item.custom_price_value || 0) * (item.quantity || 1)
          )
          .reduce((a, b) => a + b, 0)
      };
    case CartTypes.REMOVE_ITEM: {
      const newItems = state.items.filter(
        (item) => item.id !== action.payload.id
      );
      const newRemoveUpdatedItems = updateItemsWithMinimumCheck(
        newItems,
        state.sellersMinimumOrder
      );
      return {
        ...state,
        items: newRemoveUpdatedItems,
        total: newRemoveUpdatedItems
          .filter((item) => !item.hasNotMetMinimum)
          .map(
            (item) =>
              Number(item.custom_price_value || 0) * (item.quantity || 1)
          )
          .reduce((a, b) => a + b, 0)
      };
    }
    case CartTypes.EMPTY_CART: {
      return {
        ...state,
        items: [],
        total: 0
      };
    }
    case CartTypes.GET_ITEMS: {
      if (Array.isArray(action.payload)) {
        const newGetUpdatedItems = updateItemsWithMinimumCheck(
          action.payload,
          state.sellersMinimumOrder
        );
        return {
          ...state,
          items: newGetUpdatedItems,
          total: newGetUpdatedItems
            .filter((item) => !item.hasNotMetMinimum)
            .map(
              (item) =>
                Number(item.custom_price_value || 0) * (item.quantity || 1)
            )
            .reduce((a, b) => a + b, 0)
        };
      }
    }
    default:
      return state;
  }
};
