import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useEffect
} from 'react';

import { AuthContext } from '../Auth/AuthContext';
import { Basket } from '../../interfaces/Offer';
import { Action } from '../../interfaces/Action';
import { multiBasketsReducer } from './reducer';
import { useBaskets } from '../../http/hooks/basket/useBaskets';
import { getMultiBasketsItems } from './action';

export interface MultiBasketState {
  baskets: Record<string, Basket>;
}

const initialState: MultiBasketState = {
  baskets: {}
};

const BasketContext = createContext<
  | {
      state: MultiBasketState;
      dispatch: React.Dispatch<Action>;
      isFetching: boolean;
      baskets?: Basket[];
      refetch: () => void;
      setIsLoading: (value: React.SetStateAction<boolean>) => void;
    }
  | undefined
>(undefined);

export const MultiBasketsProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(multiBasketsReducer, initialState);
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedCompany } = useContext(AuthContext);

  const {
    data: baskets,
    isFetching,
    refetch
  } = useBaskets({
    companyId: selectedCompany?.id || 0,
    options: {
      enabled: !!selectedCompany?.id && selectedCompany.id !== 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (basketsReducer) => {
        const basketsObject = basketsReducer?.reduce<Record<string, Basket>>(
          (acc, basket) => {
            acc[basket?.id] = basket;
            return acc;
          },
          {}
        );
        dispatch(getMultiBasketsItems(basketsObject));
      }
    }
  });

  useEffect(() => {
    refetch();
  }, [refetch, selectedCompany]);

  const contextValue = useMemo(
    () => ({
      refetch: () => refetch(),
      baskets,
      state,
      dispatch,
      isFetching: isFetching || isLoading,
      setIsLoading
    }),
    [state, dispatch, isFetching, baskets, refetch, isLoading, setIsLoading]
  );

  return (
    <BasketContext.Provider value={contextValue}>
      {children}
    </BasketContext.Provider>
  );
};

export const useMultiBaskets = (): {
  state: MultiBasketState;
  dispatch: React.Dispatch<Action>;
  isFetching: boolean;
  baskets?: Basket[];
  refetch: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const context = useContext(BasketContext);
  if (!context) {
    throw new Error('useBasket must be used within a CartProvider');
  }
  return context;
};
