export const authApiUrl = process.env
  .NEXT_PUBLIC_AUTH_API_URL as unknown as string;

export const pageTitle = process.env
  .NEXT_PUBLIC_PAGE_TITLE as unknown as string;

export const iuguAccountId = process.env
  .NEXT_PUBLIC_IUGU_ID as unknown as string;

export const iuguTestMode = process.env
  .NEXT_PUBLIC_TEST_MODE as unknown as string;

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_ANALYTICS_ID || '';
export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_ANALYTICS_ENVIRONMENT === 'production';

export const IMAGES_BASE_URL = process.env
  .NEXT_PUBLIC_IMAGES_BASE_URL as unknown as string;

export const JSREPORT_USER = process.env.NEXT_PUBLIC_JSREPORT_USER || '';
export const JSREPORT_PASSWORD =
  process.env.NEXT_PUBLIC_JSREPORT_PASSWORD || '';

export const ORDER_WEBHOOK_URL = process.env.NEXT_PUBLIC_ORDER_WEBHOOK || '';

export const ORDER_WEBHOOK_SELLER_EMAIL_URL =
  process.env.NEXT_PUBLIC_ORDER_WEBHOOK_SELLER_EMAIL || '';
