import useMediaQuery from './useMediaQuery';

export const useDeviceType = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(min-width: 768px)');
  const isXL = useMediaQuery('(min-width: 1280px)');
  const isXXL = useMediaQuery('(min-width: 1536px)');
  const isCellphone = useMediaQuery('(max-width: 480px)');
  return { isDesktop, isTablet, isXL, isCellphone, isXXL };
};
