import { BeforeMaskedStateChangeStates, InputState } from 'react-input-mask';

export const handleFormatCnpj = (value: string | undefined) => {
  const cnpjCpf = value?.replace(/\D/g, '');
  if (cnpjCpf?.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  return cnpjCpf?.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
};

export const handleFormatCEP = (cep: string) => {
  const regex = /^(\d{5})(\d{3})$/;
  if (regex.test(cep)) {
    return cep.replace(regex, '$1-$2');
  }
  return cep;
};

export const numberRegex = /^\d+(\.\d+)?$/;

export const formatNumber = (
  number: number,
  options: Intl.NumberFormatOptions = {}
) => {
  return new Intl.NumberFormat('pt-BR', options).format(number);
};

export const formatCurrency = (amount: number) => {
  return formatNumber(amount, {
    style: 'currency',
    currency: 'BRL'
  });
};

export const maskToCurrency = (
  states: BeforeMaskedStateChangeStates
): InputState => {
  const { value } = states.nextState || {};

  let amountFormatted = value?.replace?.(/\D/g, '');
  amountFormatted = amountFormatted?.replace?.(/^0+/g, '');

  if (amountFormatted?.length === 2) {
    return {
      ...states.nextState,
      value: `R$ ${amountFormatted}`,
      selection: {
        start: amountFormatted.length + 3,
        end: amountFormatted.length + 3
      }
    };
  }

  const amountFormattedWithComma = amountFormatted?.replace?.(
    /(?=\d{2})(\d{2})$/,
    ',$1'
  );
  const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
    /(\d)(?=(\d{3})+(?!\d))/g,
    '$1.'
  );

  if (amountFormattedWithDot) {
    return {
      ...states.nextState,
      value: `R$ ${amountFormattedWithDot}`,
      selection: {
        start: amountFormattedWithDot.length + 3,
        end: amountFormattedWithDot.length + 3
      }
    };
  }

  return states.nextState;
};
