import { Category } from '../../../interfaces/Product';
import { api } from '../../axios/auth';

export const getCategories = async ({
  token
}: {
  token: string;
}): Promise<Category[]> => {
  const response = await api.get<Category[]>(`/category/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
};
