import { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult
} from 'react-query';
import { api } from '../../axios/auth';
import { MutationError } from '../../../interfaces/Mutation';
import { token } from '../../queries/token';
import { useContext } from 'react';
import { AuthContext } from '../../../provider/Auth/AuthContext';

interface CartBody {
  offers: number[];
}

export function useDeleteCartItems({
  options
}: {
  options?: UseMutationOptions<
    AxiosResponse,
    AxiosError<MutationError>,
    CartBody,
    string[]
  >;
}): UseMutationResult<AxiosResponse, AxiosError, CartBody, unknown> {
  const { selectedCompany } = useContext(AuthContext);
  return useMutation(
    (data: CartBody) =>
      api.post(`/cart/${selectedCompany?.cart}/delete_offer_cart/`, data, {
        headers: {
          Authorization: `Bearer ${token()}`
        }
      }),
    options
  );
}
