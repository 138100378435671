import { useContext, useEffect } from 'react';
import useCheckAuth from '../../utils/hooks/useAuth';
import { AuthContext } from '../../provider/Auth/AuthContext';
import { useRouter } from 'next/router';
import { pagesAllowedWithoutLogin } from '../../utils/constants/routes';
import PageLoading from '../../components/PageLoading';

export const CookieAuthenticator = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { isLoading } = useCheckAuth();
  const { user, selectedCompany } = useContext(AuthContext);
  const router = useRouter();

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (user && selectedCompany && router.route === '/login') {
        try {
          return await router.push('/');
        } catch (e) {
          console.error('Navigation failed', e);
        }
      }
      if (
        !isLoading &&
        !user &&
        !pagesAllowedWithoutLogin.includes(router.route) &&
        router.route !== '/login'
      ) {
        try {
          await router.push('/login');
        } catch (e) {
          console.error('Navigation failed', e);
        }
      }
    };
    checkAndRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, user, router]);

  return <PageLoading isLoading={isLoading}>{children}</PageLoading>;
};
