/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Link from 'next/link';
import React, { FC } from 'react';

import DefaultLogo from '../../../public/assets/images/logos/Logo.svg?svgr';
import WhiteLogo from '../../../public/assets/images/logos/white-logo.svg?svgr';
import BicolorLogo from '../../../public/assets/images/logos/bicolor-logo.svg?svgr';
import FullWhiteLogo from '../../../public/assets/images/logos/fullWhite.svg?svgr';

const Logo: FC<{
  alternative?: 'default' | 'white' | 'bicolor' | 'fullWhite';
  url?: string;
}> = ({ alternative, url }) => {
  const LogoType: React.FC = {
    default: DefaultLogo,
    white: WhiteLogo,
    bicolor: BicolorLogo,
    fullWhite: FullWhiteLogo
  }[alternative || 'default'];

  return url ? (
    <Link href={url}>
      <a>
        <LogoType />
      </a>
    </Link>
  ) : (
    <LogoType />
  );
};

export default Logo;
