import React from 'react';
import { Offer } from '../../interfaces/Offer';
import { Flex } from '../../components/Flex';
import { ImageWithFallback } from '../../components/ImageWithFallback';
import { IMAGES_BASE_URL } from '../../utils/constants/envs';
import SampleProductImage from '../../../public/assets/images/sample/sampleProductImage.png';
import ProductAmountInput from '../Cart/CartList/ProductAmountInput';
import { formatCurrency } from '../../utils/helpers/formatters';
import { HorizontalOfferTitle } from '../../components/Offer/Horizontal/HorizontalOfferTitle';
import { useDeviceType } from '../../utils/hooks/useDeviceType';
import { PurchaseOfferItem, isOffer } from '../../interfaces/Order';
import { OfferButtons } from './OfferButtons';
import Icons from '../../../public/assets/images/icons';
interface Props {
  offer: Offer | PurchaseOfferItem;
  isSearch?: boolean;
  handleAdd?: (
    offer: Offer | PurchaseOfferItem,
    value: number | undefined
  ) => void;
  handleDeleteProduct?: (offer: Offer) => void;
}
export const HorizontalOfferItem = ({
  offer,
  handleAdd,
  isSearch = false,
  handleDeleteProduct
}: Props): JSX.Element => {
  const { isTablet } = useDeviceType();
  const root = isSearch
    ? 'bg-white 2xl:hidden gap-3 min-w-[300px] md:min-w-[400px] lg:min-w-full p-3 mr-1  border-[1px] border-neutral-pureOp10 rounded-md'
    : 'bg-white 2xl:hidden gap-3 min-w-[350px] md:min-w-[400px] lg:min-w-full p-3 mr-1  border-[1px] border-neutral-pureOp10 rounded-md';
  return (
    <>
      <Flex direction="col" className={root}>
        <Flex className="gap-2">
          <Flex className="relative">
            <ImageWithFallback
              width={isTablet ? 104 : 70}
              height={isTablet ? 104 : 70}
              layout="fixed"
              objectFit="contain"
              className="bg-neutral-pure100"
              src={
                `${IMAGES_BASE_URL}${
                  isOffer(offer)
                    ? offer.commercial_product.base_product?.identifier_code
                    : offer.identifier_code
                }.jpg` || SampleProductImage
              }
              alt={
                (isOffer(offer)
                  ? offer.commercial_product.base_product?.description
                  : offer.description) || 'Produto'
              }
              fallbackSrc={SampleProductImage}
            />
            {handleDeleteProduct && (
              <Flex
                justify="center"
                align="center"
                className="cursor-pointer absolute w-[32px] h-[32px] border-[1px] 
                border-neutral-pure500 bg-white rounded-full shadow-md"
                onClick={() => {
                  handleDeleteProduct?.(offer as Offer);
                }}
              >
                <Icons.Trash className="text-neutral-pure300" />
              </Flex>
            )}
          </Flex>
          <Flex direction="col" justify="between" className="w-full">
            <HorizontalOfferTitle offer={offer} />
            <p className="font-[600] text-[20px] text-primary-pure">
              {formatCurrency(Number(offer.custom_price_value))}
            </p>
          </Flex>
        </Flex>
        <Flex
          justify="between"
          className="gap-2 w-full"
          align="center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {isSearch ? (
            <OfferButtons
              isSearch
              offer={offer as Offer}
              handleAdd={handleAdd}
            />
          ) : (
            <ProductAmountInput
              offer={offer}
              value={Number(offer.quantity)}
              callbackUpdateAmount={(offerCallback, value) =>
                handleAdd?.(offerCallback, value)
              }
            />
          )}
        </Flex>
      </Flex>
      <Flex className="hidden 2xl:flex gap-3 min-w-[350px] md:min-w-[400px] lg:min-w-full p-3 mr-1  border-[1px] border-neutral-pureOp10 rounded-md">
        <Flex className="relative">
          <ImageWithFallback
            width={isTablet ? 104 : 70}
            height={isTablet ? 104 : 70}
            layout="fixed"
            objectFit="contain"
            className="bg-neutral-pure100"
            src={
              `${IMAGES_BASE_URL}${
                isOffer(offer)
                  ? offer.commercial_product.base_product?.identifier_code
                  : offer.identifier_code
              }.jpg` || SampleProductImage
            }
            alt={
              (isOffer(offer)
                ? offer.commercial_product.base_product?.description
                : offer.description) || 'Produto'
            }
            fallbackSrc={SampleProductImage}
          />
          {handleDeleteProduct && (
            <Flex
              justify="center"
              align="center"
              className="cursor-pointer absolute w-[32px] h-[32px] border-[1px] 
                border-neutral-pure500 bg-white rounded-full shadow-md"
              onClick={() => {
                handleDeleteProduct?.(offer as Offer);
              }}
            >
              <Icons.Trash className="text-neutral-pure300" />
            </Flex>
          )}
        </Flex>
        <Flex direction="col" justify="between" className="w-full">
          <HorizontalOfferTitle offer={offer} />
          <Flex justify="between" className="gap-2" align="center">
            {isSearch ? (
              <OfferButtons
                isSearch
                offer={offer as Offer}
                handleAdd={handleAdd}
              />
            ) : (
              <ProductAmountInput
                offer={offer}
                value={Number(offer.quantity)}
                callbackUpdateAmount={(offerCallback, value) =>
                  handleAdd?.(offerCallback, value)
                }
              />
            )}
            <p className="font-[600] text-[20px] text-primary-pure">
              {formatCurrency(Number(offer.custom_price_value))}
            </p>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
