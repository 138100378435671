import ArrowDown from './arrowDown.svg?svgr';
import Vegetables from './vegetables.svg?svgr';
import Drink from './drink.svg?svgr';
import NotFoundIcon from './404.svg?svgr';
import ArrowLeft from './arrowLeft.svg?svgr';
import ArrowRight from './arrowRight.svg?svgr';
import CheckCircle from './check_circle.svg?svgr';
import ChevronLeft from './chevronLeft.svg?svgr';
import ChevronRight from './chevronRight.svg?svgr';
import Dislike from './dislike.svg?svgr';
import Instagram from './instagram.svg?svgr';
import Like from './like.svg?svgr';
import Linkedin from './linkedin.svg?svgr';
import Paste from './paste.svg?svgr';
import Replace from './replace.svg?svgr';
import SearchIcon from './searchIcon.svg?svgr';
import SquareArrowLeft from './squareArrowLeft.svg?svgr';
import SquareArrowRight from './squareArrowRight.svg?svgr';
import Car from './car.svg?svgr';
import Milk from './milk.svg?svgr';
import Dog from './dog.svg?svgr';
import Fridge from './fridge.svg?svgr';
import Bread from './bread.svg?svgr';
import Meat from './meat.svg?svgr';
import Cigarrete from './cigarrete.svg?svgr';
import Medicine from './medicine.svg?svgr';
import withIconStyles from './wrapper';
import PaintBrush from './paintBrush.svg?svgr';
import PaperClip from './paperClip.svg?svgr';
import House from './house.svg?svgr';
import Freeze from './freeze.svg?svgr';
import Broom from './broom.svg?svgr';
import Perfume from './perfume.svg?svgr';
import Baby from './baby.svg?svgr';
import Supplement from './supplement.svg?svgr';
import TeddyBear from './teddyBear.svg?svgr';
import ToiletPaper from './toiletPaper.svg?svgr';
import Basket from './basket.svg?svgr';
import Products from './products.svg?svgr';
import Candy from './candy.svg?svgr';
import Cart from './cart.svg?svgr';
import MapPin from './mapPin.svg?svgr';
import Truck from './truck.svg?svgr';
import Trash from './trash.svg?svgr';
import Search from './search.svg?svgr';
import ChevronDown from './chevronDown.svg?svgr';
import ChevronUp from './chevronUp.svg?svgr';
import Burger from './burger.svg?svgr';
import Check from './check.svg?svgr';
import Cancel from './cancel.svg?svgr';
import Box from './box.svg?svgr';
import LeafTopLeft from './leaftopleft.svg?svgr';
import LeafBottomLeft from './leafbottomleft.svg?svgr';
import LeafBottomRight from './leafbottomright.svg?svgr';
import LeafTopRight from './leaftopright.svg?svgr';
import LeafCircle from './leafcircle.svg?svgr';
import Percentage from './percentage.svg?svgr';
import BasketAdd from './basketAdd.svg?svgr';
import BasketSave from './basketSave.svg?svgr';
import Minus from './minus.svg?svgr';
import Plus from './plus.svg?svgr';
import Close from './close.svg?svgr';
import Leave from './leave.svg?svgr';
import CardLeafTopLeft from './cardleaftopleft.svg?svgr';
import CardLeafBottomLeft from './cardleafbottomleft.svg?svgr';
import CardLeafBottomRight from './cardleafbottomright.svg?svgr';
import CardLeafTopRight from './cardleaftopright.svg?svgr';
import Phone from './phone.svg?svgr';
import LogoLeafTopLeft from './logoleaftopleft.svg?svgr';
import LogoLeafTopRight from './logoleaftopright.svg?svgr';
import LogoLeafRightBottom from './logoleafrightbottom.svg?svgr';
import LogoLeafRightTop from './logoleafrighttop.svg?svgr';
import SmallLogo from './smallLogo.svg?svgr';
import EmptyCart from './emptyCart.svg?svgr';
import CheckoutBox from './checkoutBox.svg?svgr';
import CheckoutPin from './checkoutPin.svg?svgr';
import Dolar from './dolar.svg?svgr';
import CartCheck from './cartCheck.svg?svgr';
import Shield from './shield.svg?svgr';
import Pix from './pix.svg?svgr';
import CreditCard from './creditCard.svg?svgr';
import BankSlip from './bankSlip.svg?svgr';
import BakeryLeaf from './bakeryLeaf.svg?svgr';
import ReplaceArrow from './replaceArrow.svg?svgr';
import Add from './add.svg?svgr';
import User from './user.svg?svgr';

const rawIcons = {
  User,
  Add,
  ReplaceArrow,
  BakeryLeaf,
  BankSlip,
  CreditCard,
  Pix,
  Shield,
  CartCheck,
  Dolar,
  CheckoutPin,
  CheckoutBox,
  EmptyCart,
  SmallLogo,
  LogoLeafRightTop,
  LogoLeafRightBottom,
  LogoLeafTopLeft,
  LogoLeafTopRight,
  Phone,
  CardLeafTopLeft,
  CardLeafBottomLeft,
  CardLeafBottomRight,
  CardLeafTopRight,
  Leave,
  BasketSave,
  Close,
  Plus,
  Minus,
  BasketAdd,
  Percentage,
  LeafCircle,
  LeafTopLeft,
  LeafBottomLeft,
  LeafBottomRight,
  LeafTopRight,
  Box,
  Cancel,
  Check,
  Burger,
  ChevronUp,
  ChevronDown,
  Truck,
  MapPin,
  Cart,
  Candy,
  Products,
  Basket,
  ToiletPaper,
  TeddyBear,
  Supplement,
  Baby,
  Perfume,
  Broom,
  House,
  Freeze,
  PaperClip,
  PaintBrush,
  Medicine,
  Cigarrete,
  Meat,
  Bread,
  Dog,
  Milk,
  ArrowDown,
  Vegetables,
  Car,
  Drink,
  NotFoundIcon,
  ArrowLeft,
  ArrowRight,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Dislike,
  Instagram,
  Like,
  Linkedin,
  Paste,
  Replace,
  SearchIcon,
  Search,
  SquareArrowLeft,
  SquareArrowRight,
  Fridge,
  Trash
};

const Icons = Object.fromEntries(
  Object.entries(rawIcons).map(([key, IconComponent]) => {
    return [key, withIconStyles(IconComponent)];
  })
);

export default Icons;
