import React from 'react';
import { Flex } from '../../Flex';
import { Offer } from '../../../interfaces/Offer';
import { PurchaseOfferItem, isOffer } from '../../../interfaces/Order';
interface Props {
  offer: Offer | PurchaseOfferItem;
  showPercentageSaved?: boolean;
}
export const HorizontalOfferTitle = ({
  offer,
  showPercentageSaved = false
}: Props): JSX.Element => {
  return (
    <Flex direction="col" className="w-full">
      <Flex justify="between" align="center">
        <p className="font-[600] text-[10px] lg:text-[12px] text-neutral-pure700">
          {(isOffer(offer)
            ? offer.commercial_product.base_product?.identifier_code
            : offer.description) || 'Sem GTIN'}
        </p>
        <Flex className="gap-1 items-center w-full" justify="end">
          <Flex>
            {isOffer(offer) && !!offer.similar_product && (
              <Flex
                className="tooltip tooltip-bottom before:content-[attr(data-tip)] before:max-w-[100px] lg:before:max-w-[120px] lg:before:w-fit tooltip-secondary text-start"
                onClick={(e) => e.stopPropagation()}
                justify="center"
                align="center"
                data-tip={`Similar ao produto ${offer.similar_product.description}`}
              >
                <p className="px-1 font-[600] text-[14px] rounded-md text-secondary-pure bg-secondary-pureOp10">
                  Similar
                </p>
              </Flex>
            )}
          </Flex>
          {showPercentageSaved &&
            Number(offer.discount_percentage || 0) > 0 && (
              <Flex className="w-fit text-success-pure bg-success-pureOp10 px-1 rounded-md">
                <p className="text-[16px] font-[700]">
                  - {(Number(offer.discount_percentage || 0) * 100).toFixed()}%
                </p>
              </Flex>
            )}
        </Flex>
      </Flex>
      <Flex
        className="tooltip tooltip-bottom tooltip-primary text-start"
        data-tip={
          (isOffer(offer)
            ? offer.commercial_product.base_product?.description
            : offer.description) || 'Produto'
        }
      >
        <p
          style={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
          }}
          className="font-[600] text-[14px] lg:text-[16px] text-neutral-pure1000"
        >
          {isOffer(offer)
            ? offer.commercial_product.base_product?.description
            : offer.description}
        </p>
      </Flex>
    </Flex>
  );
};
