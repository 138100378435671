import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { getCartItems } from '../../queries/cart/getCartItems';
import { Cart } from '../../../interfaces/Cart';
import { token } from '../../queries/token';

export function useCartItems({
  companyId,
  options
}: {
  companyId: number;
  options?: UseQueryOptions<Cart[], unknown, Cart[], string[]>;
}): UseQueryResult<Cart[]> {
  return useQuery(
    ['cart', `${companyId}`],
    async () => getCartItems({ companyId, token: token() }),
    options
  );
}
