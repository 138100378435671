import { Seller } from '../../../interfaces/Seller';
import { api } from '../../axios/auth';

export const getSellers = async ({
  token
}: {
  token?: string;
}): Promise<Seller[]> => {
  const response = await api.get<Seller[]>(`/seller/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
};
