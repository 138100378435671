/* eslint-disable @next/next/no-page-custom-font */
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { pageTitle } from '../../utils/constants/envs';

interface Props {
  subtitle?: string;
}

const PageMeta = ({ subtitle }: Props) => {
  const [title, setTitle] = useState<string>(pageTitle);

  useEffect(() => {
    if (subtitle) {
      setTitle(`${pageTitle} | ${subtitle}`);
    } else {
      setTitle(pageTitle);
    }
  }, [subtitle]);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content="Painel de pedidos da BakeryTech" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/images/logos/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/images/logos/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/images/logos/favicon-16x16.png"
      />

      <link rel="manifest" href="/assets/images/logos/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
};

export default PageMeta;
