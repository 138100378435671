import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useEffect,
  useState
} from 'react';
import { CartItem } from '../../interfaces/Cart';
import { cartReducer } from './reducer';
import { Action } from '../../interfaces/Action';
import { AuthContext } from '../Auth/AuthContext';
import { emptyCart, getCartItems, getSellersMinimum } from './actions';
import { useCartItems } from '../../http/hooks/cart/useCartItems';
import { useSellers } from '../../http/hooks/seller/useSellers';
import { CartModal } from '../../features/Cart/Modal/CartModal';

export interface CartState {
  items: CartItem[];
  total: number;
  sellersMinimumOrder: { [sellerId: number]: number };
}

const initialState: CartState = {
  items: [],
  total: 0,
  sellersMinimumOrder: {}
};

const CartContext = createContext<
  | {
      state: CartState;
      dispatch: React.Dispatch<Action>;
      isFetching: boolean;
      setIsOpen: () => void;
    }
  | undefined
>(undefined);

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const { selectedCompany } = useContext(AuthContext);
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [isOpen, setIsOpen] = useState(false);

  const { isFetching } = useCartItems({
    companyId: selectedCompany?.id || 0,
    options: {
      enabled: !!selectedCompany?.id,
      refetchOnWindowFocus: false,
      onSuccess: (apiData) => {
        dispatch(getCartItems(apiData?.[0]?.offers));
      }
    }
  });

  const { isFetching: isFetchingSellers } = useSellers({
    options: {
      enabled: !!selectedCompany?.id,
      refetchOnWindowFocus: false,
      onSuccess: (apiData) => {
        dispatch(getSellersMinimum(apiData));
      }
    }
  });

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      isFetching: isFetching || isFetchingSellers,
      setIsOpen: () => setIsOpen((oldState) => !oldState)
    }),
    [state, dispatch, isFetching, isFetchingSellers, setIsOpen]
  );

  useEffect(() => {
    dispatch(emptyCart());
  }, [selectedCompany]);

  return (
    <CartContext.Provider value={contextValue}>
      <>
        <CartModal isOpen={isOpen} />
        {children}
      </>
    </CartContext.Provider>
  );
};

export const useCart = (): {
  state: CartState;
  dispatch: React.Dispatch<Action>;
  isFetching: boolean;
  setIsOpen: () => void;
} => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
